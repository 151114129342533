import { makeStyles } from "@material-ui/core"

export const useHelperStyles = makeStyles(() => ({
  maxTwoLines: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
}))
