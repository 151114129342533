import React from "react"
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  makeStyles,
  Typography,
} from "@material-ui/core"
import clsx from "clsx"
import { round, minBy } from "lodash"
import { Link } from "gatsby"
import { useHelperStyles } from "../../styles/helpers"
import { getSlug, selectBestImageUrl } from "../../utils"
import { FadeInImage } from "../FadeIntoView"
// @ts-ignore
import STOCK_IMAGE_PLACEHOLDER from "../../images/whisky-stock.jpg"
import { ProductItemCardProps } from "./ProductItemCardProps"

const useStyles = makeStyles(theme => ({
  content: {
    flex: "1 0 auto",
  },
  coverSmallScreen: {
    width: 144,
    height: 144,
    backgroundSize: "contain",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  title: {
    minHeight: `calc(${theme.typography.h5.lineHeight} * 1.4993rem * 2)`, // TODO: get the real media adjusted height
  },
}))

export const MobileProductItemCard = ({ product }: ProductItemCardProps) => {
  const classes = useStyles()
  const utilClasses = useHelperStyles()

  const { title, listings, category, id } = product
  const imgUrl = selectBestImageUrl(product.listings)

  const cheapestListing = minBy(
    product.listings,
    listing => listing.price / listing.volume_cl
  )
  const slug = product.extra.slug ?? getSlug({ title, category, id })

  const [deal] =
    product.listings.find(listing => listing.deals.length)?.deals || []

  const savings = round(deal?.price_change_percent || 0, 2)
  return (
    <Box width="100%" position="relative" borderRadius="16px">
      <Card>
        {deal && (
          <Box position="absolute" top="8px" left="8px" zIndex="1">
            <Chip color="primary" label={`${savings}%`} />
          </Box>
        )}
        <Link to={slug} className={classes.link}>
          <Box>
            <CardActionArea>
              <Box display="flex">
                <Box>
                  <FadeInImage
                    image={imgUrl || STOCK_IMAGE_PLACEHOLDER}
                    title={title}
                    className={classes.coverSmallScreen}
                  ></FadeInImage>
                </Box>
                <Box>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h3"
                      className={clsx(utilClasses.maxTwoLines)}
                    >
                      {title}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {`from `}
                      <Box component="span" fontWeight="bold" fontSize={16}>
                        {`£${round(
                          (cheapestListing?.price /
                            cheapestListing?.volume_cl) *
                            70,
                          2
                        )}`}
                      </Box>
                      {` / 70cl`}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {`Available from ${listings.length} listings`}
                    </Typography>
                  </CardContent>
                </Box>
              </Box>
            </CardActionArea>
          </Box>
        </Link>
      </Card>
    </Box>
  )
}
