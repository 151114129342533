import { gql } from "@apollo/client"
import { ProductItemCardFragment } from "./__generated__/ProductItemCardFragment"

export const productItemCardFragment = gql`
  fragment ProductItemCardFragment on products {
    id
    title
    category
    extra
    listings {
      product_image_url
      price
      title
      url
      store
      volume_cl
      deals {
        price_change_percent
      }
    }
  }
`

export interface ProductItemCardProps {
  product: ProductItemCardFragment
}
