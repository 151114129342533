import React from "react"
import { Box } from "@material-ui/core"
import { graphql } from "gatsby"
// @ts-ignore
import STOCK_IMAGE_PLACEHOLDER from "../../images/whisky-stock.jpg"
import { ProductItemCardProps } from "./ProductItemCardProps"
import { DesktopProductItemCard } from "./DesktopProductItemCard"
import { MobileProductItemCard } from "./MobileProductItemCard"

export const ProductItemCard: React.FC<ProductItemCardProps> = props => {
  return (
    <>
      <Box width={280} display={{ xs: "none", md: "block" }}>
        <DesktopProductItemCard {...props}></DesktopProductItemCard>
      </Box>
      <Box width="100%" display={{ xs: "block", md: "none" }}>
        <MobileProductItemCard {...props}></MobileProductItemCard>
      </Box>
    </>
  )
}

export const staticFragment = graphql`
  fragment ProductItemCardFragment on Hasura_products {
    id
    title
    category
    extra
    listings {
      product_image_url
      price
      title
      url
      store
      volume_cl
      deals {
        price_change_percent
      }
    }
  }
`
